* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App > input {
  width: 200px;
  display: block;
  border: 1px solid grey;
  outline: none;
  font-size: unset;
  padding: 8px 4px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.App > button {
  width: 200px;
  display: block;
  background-color: rgb(75, 107, 204);
  color: white;
  border: none;
  outline: none;
  font-size: unset;
  padding: 8px;
  border-radius: 6px;
  margin-top: 8px;
  cursor: pointer;
}

.App > button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 960px) {
  .App > input {
    width: calc(100% - 16px);
  }
  
  .App > button {
    width: calc(100% - 16px);
  }  
}
